import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty } from 'vant'
import { Popup, Toast, Dialog } from 'vant'
import { Form } from 'vant'
import { Uploader } from 'vant'

Vue.use(Uploader)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Dialog)
export default {
  name: 'uplaodata',
  components: {},
  data () {
    return {
      zz:'[camera]',
      bxClaimsShopName: '',
      bxClaimsOrderNo: '',
      isSubmit: false,
      uploadFile: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      },
      uploadFile1: {
        bxClaimsCertificateImgs: [],
        bxClaimsCaseHistoryImgs: [],
        bxClaimsBankImgs: [],
        bxClaimsCostProveImgs: [],
        bxClaimsPathologicalDiagnosisReportImgs: [],//病理诊断报告
        bxClaimsPrescriptionDrugImgs: [],//药品处方
        bxClaimsGeneticTestingReportImgs: [],//免疫组化/基因检测报告
      }
    }
  },
  beforeCreate () {

  },
  created () {
    this.bxClaimsShopName = this.$store.state.DetailList.bxClaimsShopName
    this.bxClaimsOrderNo = this.$store.state.DetailList.bxClaimsOrderNo
    this.uploadFile1 = this.$store.state.uploadFiles
    ////////
    var CertificateImgsList = this.$store.state.uploadFiles.bxClaimsCertificateImgs
    for (let i = 0; i < CertificateImgsList.length; i++) {
      this.uploadFile.bxClaimsCertificateImgs.push({ 'url': CertificateImgsList[i] })
    }
    //////////////
    var CaseHistoryImgsList = this.$store.state.uploadFiles.bxClaimsCaseHistoryImgs
    for (let i = 0; i < CaseHistoryImgsList.length; i++) {
      this.uploadFile.bxClaimsCaseHistoryImgs.push({ 'url': CaseHistoryImgsList[i] })
    }
    //////////////
    var bxClaimsBankImgsList = this.$store.state.uploadFiles.bxClaimsBankImgs
    for (let i = 0; i < bxClaimsBankImgsList.length; i++) {
      this.uploadFile.bxClaimsBankImgs.push({ 'url': bxClaimsBankImgsList[i] })
    }
    /////////////////////
    var CostProveImgsList = this.$store.state.uploadFiles.bxClaimsCostProveImgs
    for (let i = 0; i < CostProveImgsList.length; i++) {
      this.uploadFile.bxClaimsCostProveImgs.push({ 'url': CostProveImgsList[i] })
    }
    //////////病历
    var PathologicalDiagnosisReportImgsList = this.$store.state.uploadFiles.bxClaimsPathologicalDiagnosisReportImgs
    for (let i = 0; i < PathologicalDiagnosisReportImgsList.length; i++) {
      this.uploadFile.bxClaimsPathologicalDiagnosisReportImgs.push({ 'url': PathologicalDiagnosisReportImgsList[i] })
    }
    //////////处方
    var PrescriptionDrugImgList = this.$store.state.uploadFiles.bxClaimsPrescriptionDrugImgs
    for (let i = 0; i < PrescriptionDrugImgList.length; i++) {
      this.uploadFile.bxClaimsPrescriptionDrugImgs.push({ 'url': PrescriptionDrugImgList[i] })
    }
    /////////免疫组化
    var GeneticTestingReportImgList = this.$store.state.uploadFiles.bxClaimsGeneticTestingReportImgs
    for (let i = 0; i < GeneticTestingReportImgList.length; i++) {
      this.uploadFile.bxClaimsGeneticTestingReportImgs.push({ 'url': GeneticTestingReportImgList[i] })
    }

  },
  methods: {
    ...mapActions('claims', ['uploadFlies', 'subApplyClaims']),
    back () {
      this.$router.go(-1)
    },

    toinfoSure () {

      if (this.uploadFile1.bxClaimsCertificateImgs.length <= 0) {
        Toast('请上传投保时使用的证件')
      } else if (this.uploadFile1.bxClaimsCaseHistoryImgs.length <= 0) {
        Toast('请上传门(急)诊病历、住院病历、出院小结')
      } else if (this.uploadFile1.bxClaimsBankImgs.length <= 0) {
        Toast('请上传银行卡信息')
      } else {
        this.$router.push({ name: 'infoSure' })
      }

    },
    beforeRead (file) {//判断上传图片是否超过10M
      if (file instanceof Array) {
        var isflag = true
        for (let i = 0; i < file.length; i++) {
          if (file[i].size > 10 * 1024 * 1024) {
            Toast('上传的单张图片不能超过10M')
            isflag = false
            break

          } else {
            isflag = true
          }

        }
        return isflag

      } else {
        if (file.size > 10 * 1024 * 1024) {
          Toast('上传的单张图片不能超过10M')
          return false

        } else {
          return true
        }

      }
    },
    afterRead (file, tagging) {
      var that = this
      // 此时可以自行将文件上传至服务器
      var formData1 = new FormData()
      if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          formData1.append("files", file[i].file)
        }
      } else {
        formData1.append("files", file.file)
      }
      this.uploadFlies(formData1).then(res => {
        if (res.code == 200) {
          var resdata = res.data.split(',')
          for (let i = 0; i < resdata.length; i++) {
            if (tagging.name == '1') {
              that.uploadFile1.bxClaimsCertificateImgs.push(resdata[i])
            } else if (tagging.name == '2') {
              that.uploadFile1.bxClaimsCaseHistoryImgs.push(resdata[i])
            } else if (tagging.name == '7') {
              that.uploadFile1.bxClaimsBankImgs.push(resdata[i])
            } else if (tagging.name == '3') {
              that.uploadFile1.bxClaimsCostProveImgs.push(resdata[i])
            } else if (tagging.name == '4') {
              that.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.push(resdata[i])
            } else if (tagging.name == '5') {
              that.uploadFile1.bxClaimsPrescriptionDrugImgs.push(resdata[i])
            } else if (tagging.name == '6') {
              that.uploadFile1.bxClaimsGeneticTestingReportImgs.push(resdata[i])
            }
          }
          if (tagging.name == '1') {
            this.$store.commit('setFile', this.uploadFile1.bxClaimsCertificateImgs)
          } else if (tagging.name == '2') {
            this.$store.commit('setFile1', this.uploadFile1.bxClaimsCaseHistoryImgs)
          } else if (tagging.name == '7') {
            this.$store.commit('setFile6', this.uploadFile1.bxClaimsBankImgs)
          } else if (tagging.name == '3') {
            this.$store.commit('setFile2', this.uploadFile1.bxClaimsCostProveImgs)
          } else if (tagging.name == '4') {
            this.$store.commit('setFile3', this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs)
          } else if (tagging.name == '5') {
            this.$store.commit('setFile4', this.uploadFile1.bxClaimsPrescriptionDrugImgs)
          } else if (tagging.name == '6') {
            this.$store.commit('setFile5', this.uploadFile1.bxClaimsGeneticTestingReportImgs)
          }

        } else {
          Toast(res.code)
        }
      })
    },
    deleteOne (file, detail) {
      this.uploadFile.bxClaimsCertificateImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCertificateImgs.splice(detail.index, 1)
      this.$store.commit('setFile', this.uploadFile1.bxClaimsCertificateImgs)
    },
    //////////////
    deleteOne1 (file, detail) {
      this.uploadFile.bxClaimsCaseHistoryImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCaseHistoryImgs.splice(detail.index, 1)
      this.$store.commit('setFile1', this.uploadFile1.bxClaimsCaseHistoryImgs)

    },
    //////////////
    deleteOne6 (file, detail) {
      this.uploadFile.bxClaimsBankImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsBankImgs.splice(detail.index, 1)
      this.$store.commit('setFile6', this.uploadFile1.bxClaimsBankImgs)

    },
    //////////////////////////////////
    deleteOne2 (file, detail) {
      this.uploadFile.bxClaimsCostProveImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsCostProveImgs.splice(detail.index, 1)
      this.$store.commit('setFile2', this.uploadFile1.bxClaimsCostProveImgs)
    },
    ////////////////////病历
    deleteOne3 (file, detail) {
      this.uploadFile.bxClaimsPathologicalDiagnosisReportImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.splice(detail.index, 1)
      this.$store.commit('setFile3', this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs)
    },
    /////处方
    deleteOne4 (file, detail) {
      this.uploadFile.bxClaimsPrescriptionDrugImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsPrescriptionDrugImgs.splice(detail.index, 1)
      this.$store.commit('setFile4', this.uploadFile1.bxClaimsPrescriptionDrugImgs)
    },
    ///免疫
    deleteOne5 (file, detail) {
      this.uploadFile.bxClaimsGeneticTestingReportImgs.splice(detail.index, 1)
      this.uploadFile1.bxClaimsGeneticTestingReportImgs.splice(detail.index, 1)
      this.$store.commit('setFile5', this.uploadFile1.bxClaimsGeneticTestingReportImgs)
    },
    /////////处方
    temporaryStorage () {
      this.isSubmit = true

      // 处理逻辑
      var claimsList = this.$store.state.DetailList
      claimsList.bxClaimsCertificateImgs = this.uploadFile1.bxClaimsCertificateImgs.join(',')
      claimsList.bxClaimsCaseHistoryImgs = this.uploadFile1.bxClaimsCaseHistoryImgs.join(',')
      claimsList.bxClaimsBankImgs = this.uploadFile1.bxClaimsBankImgs.join(',')
      claimsList.bxClaimsCostProveImgs = this.uploadFile1.bxClaimsCostProveImgs.join(',')
      //////新增
      claimsList.bxClaimsPathologicalDiagnosisReportImgs = this.uploadFile1.bxClaimsPathologicalDiagnosisReportImgs.join(',')
      claimsList.bxClaimsPrescriptionDrugImgs = this.uploadFile1.bxClaimsPrescriptionDrugImgs.join(',')
      claimsList.bxClaimsGeneticTestingReportImgs = this.uploadFile1.bxClaimsGeneticTestingReportImgs.join(',')
      claimsList.bxClaimsState = 0
      var that = this
      this.subApplyClaims(claimsList).then(res => {
        if (res.code == 200) {
          Toast({
            message: '暂存成功', onClose: function () {
              that.changeData()
              that.$router.push({ name: 'claimList' })
              this.isSubmit = false
            }
          })
        } else {
          Toast(res.msg)
          this.isSubmit = false
        }
      })


    }











  },
}
